import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import Carousel from 'react-multi-carousel';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const sanitizer = DOMPurify.sanitize;

const responsive = {
	xl: {
		breakpoint: { max: 5000, min: 1920 },
		items: 4
	},
	lg: {
		breakpoint: { max: 1919, min: 1280 },
		items: 4
	},
	md: {
		breakpoint: { max: 1279, min: 960 },
		items: 4
	},
	sm: {
		breakpoint: { max: 959, min: 600 },
		items: 3
	},
	xs: {
		breakpoint: { max: 599, min: 0 },
		items: 2
	}
};

const NewsPapersCarrousel = () => {
	const newspapers = [
		{
			icon: '/images/newspapers/listin_diario.svg',
			text: 'Logo Listin Diario',
			name: 'listin_diario.svg',
			width: 120,
			height: 50,
			url: 'https://listindiario.com/las-sociales/2021/08/09/683011/una-nueva-propuesta-de-salud-llega-a-republica-dominicana'
		},
		{
			icon: '/images/newspapers/listin_usa.png',
			text: 'Logo Listin Diario',
			name: 'listin_usa.png',
			width: 120,
			height: 50,
			classes: "w-full md:w-9/12 lg:w-8/12 xl:w-6/12 mx-auto",
			url: 'https://listinusa.net/2021/11/16/asistensi-sos-presenta-plataforma-de-telemedicina-gratis-a-migrantes-dominicanos/'
		},
		{
			icon: '/images/newspapers/diario_libre.svg',
			text: 'Logo Diario Libre',
			name: 'diario_libre.svg',
			width: 120,
			height: 50,
			url: 'https://www.diariolibre.com/usa/actualidad/crean-seguro-medico-dedicado-a-la-diaspora-dominicana-EM27810127'
		},
		{
			icon: '/images/newspapers/nuevo_diario.svg',
			text: 'Logo El Nuevo Diario',
			name: 'nuevo_diario.svg',
			width: 120,
			height: 50,
			url: 'https://elnuevodiario.com.do/llega-a-la-republica-dominicana-asistensi-para-hacer-mas-accesible-la-salud-a-todos/'
		},
		{
			icon: '/images/newspapers/acento.svg',
			text: 'Logo acento',
			name: 'acento.svg',
			width: 120,
			height: 50,
			url: 'https://acento.com.do/sociales/asistensi-s-o-s-ofrece-orientacion-medica-a-distancia-8944926.html'
		}
	];

	return (
		<div className="w-full max-w-screen-xl pt-16 pb-6 mx-auto bg-white">
			<style>
				{`
          .min-size-arrow {
            min-width: 24px;
            min-height: 24px;
          }

          .react-multiple-carousel__arrow {
            z-index: 10 !important;
          }

        `}
			</style>
			<div className="relative px-5 sm:px-10 lg:px-32">
				<Carousel
					swipeable={true}
					draggable={true}
					responsive={responsive}
					arrows={true}
					showDots={false}
					partialVisible={false}
					className="items-center md:flex md:pl-64 md:justify-center"
					customRightArrow={
						<KeyboardArrowRightIcon
							className="text-pink-500 bg-transparent react-multiple-carousel__arrow min-size-arrow"
							style={{ right: '-7px' }}
						/>
					}
					customLeftArrow={
						<KeyboardArrowLeftIcon
							className="text-pink-500 bg-transparent react-multiple-carousel__arrow min-size-arrow"
							style={{ left: '-7px' }}
						/>
					}
				>
					{newspapers.map((item, index) => (
						<div
							key={index}
							className="flex flex-col items-center justify-start w-full px-5"
							style={{ minWidth: item.width, height: item.height }}
						>
							<div className="flex items-center justify-center h-full">
								<a
									className="cursor-pointer"
									href={item.url}
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										loading="lazy"
										name={item.name}
										src={item.icon}
										alt={item.text}
										className={`object-cover ${item.classes}`}
									/>
								</a>
							</div>
						</div>
					))}
				</Carousel>
			</div>
		</div>
	);
};

export default NewsPapersCarrousel;
