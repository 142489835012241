import React from 'react';
import Link from 'next/link';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const banner1 = `${serverUrlImages}/MX/home/img1.png`;
const banner2 = `${serverUrlImages}/MX/home/atencion_medica.png`;
const banner3 = `${serverUrlImages}/RD/rd-seguro-hospitalizacion.jpeg`;
const banner4 = `${serverUrlImages}/RD/rd-ambulancias.jpeg`;
const banner5 = `${serverUrlImages}/RD/rd-laboratory.jpeg`;
const banner6 = `${serverUrlImages}/RD/rd-consulta-especialista.png`;

export const responsive = {
	xl: {
		breakpoint: { max: 5000, min: 1920 },
		items: 3
	},
	lg: {
		breakpoint: { max: 1919, min: 1280 },
		items: 3
	},
	md: {
		breakpoint: { max: 1279, min: 960 },
		items: 2
	},
	sm: {
		breakpoint: { max: 959, min: 600 },
		items: 2
	},
	xs: {
		breakpoint: { max: 599, min: 0 },
		items: 1
	}
};

export const data_one = [
	{
		img: {
			src: banner1,
			alt: 'Telemedicina ilimitada',
			name: 'medico-cabecera-virtual-dominicana.jpg'
		},
		title: 'Telemedicina ilimitada',
		subtitle: <span>Nuestros doctores generales están disponibles para atender cualquier consulta médica las 24 horas del día. Conecta con ellos en menos de 20 segundos, por teléfono y videollamada a través de nuestra App <span className="text-pink-500 font-bold font-poppins">asistensi</span></span>
	},
	{
		img: {
			src: banner2,
			alt: 'Atención médica domiciliaria ilimitada',
			name: 'lectura-examenes-medicos.png'
		},
		title: 'Atención médica domiciliaria ilimitada',
		subtitle: (
			<span>
				Siempre que sea necesario, nuestros médicos irán a su casa para realizar la consulta e indicar tratamiento
			</span>
		)
	},
	{
		img: {
			src: banner3,
			alt: 'Seguro de hospitalización para emergencias médicas por enfermedades y accidentes',
			name: 'medico-cabecera-virtual-dominicana.jpg'
		},
		title: 'Seguro de hospitalización para emergencias médicas por enfermedades y accidentes',
		subtitle: (
			<span>
				Brinda acceso a más de 500 centros de salud privados de la red de Humano Seguros contando con su respaldo y experiencia.<br/>
				Incluye cobertura de hasta RD $600,000 por evento, por año, suficiente para cubrir más del 90% de los casos más comunes
			</span>
		)
	}
	
];

export const data_two = [
	{
		img: {
			src: banner4,
			alt: 'Traslados en ambulancia',
			name: 'img-laboratorio.png'
		},
		title: 'Traslados en ambulancia',
		subtitle: (
			<span>
				En caso de necesitar un traslado urgente a un centro de salud, nuestros médicos coordinarán su recogida en ambulancias equipadas con soporte avanzado de vida
			</span>
		)
	},
	{
		img: {
			src: banner5,
			alt: 'Pruebas de laboratorio',
			name: 'servicios-ambulancias.jpg'
		},
		title: 'Pruebas de laboratorio',
		subtitle: (
			<span>
			Para poder recibir un mejor diagnóstico y tratamiento nuestros planes cubren hasta cuatro pruebas de laboratorio al año. Consulta las pruebas cubiertas <a href="faq/?q=20" target="_blank"><u>aquí</u></a>
			</span>
		)
	},
	{
		img: {
			src: banner6,
			alt: 'Consultas con especialistas',
			name: 'consulta-especialista.jpg'
		},
		title: 'Consultas con especialistas',
		subtitle: (
			<span>
				Incluye 2 consultas al año con especialistas (4 con el plan asistensi Plus) de cualquier área médica
			</span>
		)
	}
];

export const Title = () => (
	<h2 className="mt-5 text-purple-500 text-center font-semibold text-2xl md:text-4xl leading-snug mb-4 font-poppins">
		Nuestros servicios
	</h2>
);

export const PrimaryComponent = ({ item, index, lastItem }) => (
	<>
		<style global jsx>
			{`
				.transform-scale:hover .scale-children {
					transform: scale(1) !important;
					animation-name: fadeIn !important;
					animation-fill-mode: both !important;
					-webkit-animation-duration: 1s !important;
					animation-duration: 1s !important;
					-webkit-animation-fill-mode: both !important;
				}
				.min-size-arrow {
					min-width: 24px;
					min-height: 24px;
				}
				.react-multiple-carousel__arrow {
					z-index: 10 !important;
					background-color: #fff !important;
				}
			`}
		</style>
		<div className="my-4 mx-3 relative transition duration-300 ease-in-out min-h-93 rounded select-none mr-3">
			<img
				src={item.img?.src}
				className="rounded-xl pointer-events-none select-none w-full"
				name={item.img?.name}
				alt={item.img?.alt}
			/>
			<p className="text-lg font-bold leading-snug text-gray-800 mt-4 pointer-events-none select-none text-center h-14 font-poppins">
				{item.title}
			</p>
			<div>
				<p className="text-base text-gray-800 leading-relaxed mt-4 lg:mt-6 xl:mt-1 select-none text-center font-poppins">
					{item.subtitle}.
				</p>
			</div>
		</div>
	</>
);

export const SecondaryComponent = () => (
	<div className="flex w-full justify-center mt-3 mb-0">
		<Link href="/benefits" as="/servicios">
			<button className="white-btn">Ver más servicios de salud</button>
		</Link>
	</div>
);
