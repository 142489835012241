import React from 'react';

const StickyButton = ({ className, isSticky, onClick }) => {
	return (
		<a
			onClick={onClick}
			className={`${className} primary-button text-xl h-14`}
			style={{
				display: isSticky ? 'flex' : 'none',
				bottom: 0,
				width: '100%',
				borderRadius: 0,
				borderTopColor: 'white',
				zIndex: 20
			}}
		>
			¡Cotiza un plan!
		</a>
	);
};

export default StickyButton;
