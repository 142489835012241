import React, {useEffect} from 'react';

const TrustPilot = (props) => {
  const ref = React.useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    React.cloneElement(props.children, {ref})
  );
};

export default TrustPilot;
