import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

import { LinearProgressBar } from 'components/widgets';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import LayoutContainer from 'components/layout/LayoutContainer';
import StickyButton from 'components/home/StickyButton';
import useSticky from 'components/hooks/useSticky';
import { FastQuotation } from 'components/forms';
import StickyCall from '../src/components/home/StickyCall';
import NewsPapersCarrousel from '../src/components/home/NewsPaperCarrousel';
import {
	data as partnersData,
	Title as PartnerTitle,
	PrimaryComponent as PartnerComp
} from 'components/home/Partners';
import HireComponent from '../src/components/home/HireComponent';

import {
	responsive as benefitsResponsive,
	data_one as benefitsDataOne,
	data_two as benefitsDataTwo,
	Title as BenefitTitle,
	PrimaryComponent as BenefitComp
	// SecondaryComponent as SecBenefitComp,
} from 'components/home/childrensCarousel/benefits';

import CarouselContainer from '../src/components/home/CarouselContainer';
import TrustBox from "../src/components/trustPilot/TrustBox";

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;

const MainHeader = dynamic(
	() => import('components/home/mainHeader/MainHeader'),
	{ loading: () => <LinearProgressBar showLogo={false} /> }
);
const Testimonials = dynamic(() => import('components/home/Testimonials'), {
	loading: () => <LinearProgressBar showLogo={false} />
});
const BenefitsCarousel = dynamic(
	() => import('components/home/BenefitsCarousel'),
	{ loading: () => <LinearProgressBar showLogo={false} /> }
);
const ContainerVideo = dynamic(
	() => import('components/widgets/ContainerVideo'),
	{ loading: () => <LinearProgressBar showLogo={false} /> }
);

const bgVideo = `${serverUrlImages}/RD/asistensi-republica-dominicana.png`;

const HomePage = () => {
	const router = useRouter();
	const canonicalURL =
		process.env.REACT_APP_DOMAIN_URL + (router?.asPath || router?.pathname);

	const { isSticky, element, cotizaElement } = useSticky();
	const [showVideo, setShowVideo] = useState(false);
	const showButton = true;

	const handleVideo = (showV) => {
		setShowVideo(showV);
	};

	const goToHomeQuotation = () => {
		let dims =
			cotizaElement.current.getBoundingClientRect().top + window.pageYOffset;
		window.scrollTo(0, dims - 100);
		const event_quotation = {
			dataLayer: {
				event: 'evento-boton-home-quiero-cotizar'
			}
		};
		TagManager.dataLayer(event_quotation);
	};

	useEffect(() => {
		const tagManagerArgs = {
			dataLayer: {
				event: 'pageview',
				pageTitle: 'asistensi Dominicana | Seguros de emergencias médicas'
			}
		};
		TagManager.dataLayer(tagManagerArgs);

		if (location?.pathname) {
			if (router.asPath !== '/') {
				const { nick_promoter, coupon } = router.query;
				let coupon_promoter = '';
				if (nick_promoter) {
					if (coupon) coupon_promoter = coupon;
					const inCookie = Cookies.get('influencer');
					if (!inCookie)
						Cookies.set(
							'influencer',
							JSON.stringify({ nick_promoter, coupon_promoter }),
							{ expires: 90 }
						);
				}
			}
		}
	}, []);

	// const handleScroll = () => {
	//   if ( promoElement.current.getBoundingClientRect().bottom > 300 && slide==0) setShowButton(false)
	//   else setShowButton(true);
	// }

	// useEffect(() => {
	//   window.addEventListener('scroll', handleScroll);
	//   return () => {
	//     window.removeEventListener('scroll', handleScroll);
	//   }
	//  }, [handleScroll]);

	return (
		<LayoutContainer>
			<Head>
				<link rel="canonical" href={canonicalURL} />
			</Head>
			<div className="relative overflow-hidden">
				{showButton && (
					<StickyButton
						className="fixed md:hidden"
						isSticky={isSticky}
						onClick={goToHomeQuotation}
					/>
				)}
				<StickyCall />
				<MainHeader element={element} goToHomeQuotation={goToHomeQuotation} />
			</div>

			<NewsPapersCarrousel />

			{/**Why to Hire Asistensi */}

			<HireComponent/>
			
			<div
				id="cotizacion"
				className="bg-white container py-4 md:py-20"
				ref={cotizaElement}
			>
				<FastQuotation origin="Cotización" fromHome={true} />
			</div>
			
			<div className="py-10 container flex flex-col relative md:flex-row md:items-start">
				<div className="w-full flex flex-col md:order-2 md:w-1/2 md:ml-8 mt-14 md:mt-14 sm:mt-1">
					<h2 className="text-3xl lg:text-5xl text-purple-500 font-poppins font-semibold">
						{' '}
						¿Qué es{' '}
						<span className="text-pink-500 font-poppins">asistensi</span>?{' '}
					</h2>
					<p className="text-gray-750 leading-relaxed text-base lg:text-lg xl:text-xl mt-4">
					Somos la solución que te brinda tranquilidad protegiendo la salud de los tuyos frente a los malestares del día a día como una gastroenteritis, enfermedades comunes como por ejemplo una infección urinaria y emergencias médicas como puede serlo una fractura o un infarto.
					</p>
				</div>
				<div className="rounded-2xl flex justify-center items-center w-full relative overflow-hidden mt-16 md:mt-0 md:w-1/2">
					<div className="w-full h-auto">
						<img
							src={bgVideo}
							name="asistensi-republica-dominicana"
							alt="¿Qué es asistensi República Dominicana?"
							width={900}
							height={600}
						/>
					</div>
					{/* <img src={bgVideo} alt="familia feliz" className="w-full h-auto"/> */}
					<div className="absolute top-0 left-0 h-full w-full rounded"></div>
					<div className="cursor-pointer absolute bg-white p-5 rounded-full">
						<PlayArrowIcon
							className="text-pink-500 sm-icon"
							onClick={() => handleVideo(true)}
						/>
					</div>
				</div>
				{showVideo === true && (
					<ContainerVideo
						src="https://player.vimeo.com/video/697005950?h=3fc58eb6ce"
						handleClose={() => handleVideo(false)}
					/>
				)}
			</div>

			{/* Benefits carousels */}
			<CarouselContainer
				responsive={benefitsResponsive}
				data={benefitsDataOne}
				Title={BenefitTitle}
				PrimaryComponent={BenefitComp}
			/>
			<CarouselContainer
				responsive={benefitsResponsive}
				data={benefitsDataTwo}
				PrimaryComponent={BenefitComp}
			/>
			<TrustBox/>

			{/* partners carousel */}
			<CarouselContainer
				data={partnersData}
				Title={PartnerTitle}
				PrimaryComponent={PartnerComp}
				isCenter
				isContainer={false}
				extraMargin={true}
			/>
		</LayoutContainer>
	);
};

export default HomePage;
