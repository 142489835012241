import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import useWindowSize from 'components/hooks/useWindowSize';

const responsiveDefault = {
	xl: {
		breakpoint: { max: 5000, min: 1920 },
		items: 5,
		partialVisibilityGutter: 50
	},
	lg: {
		breakpoint: { max: 1919, min: 1280 },
		items: 5,
		partialVisibilityGutter: 20
	},
	md: {
		breakpoint: { max: 1279, min: 960 },
		items: 4,
		partialVisibilityGutter: 20
	},
	sm: {
		breakpoint: { max: 959, min: 600 },
		items: 3,
		partialVisibilityGutter: 50
	},
	xs: {
		breakpoint: { max: 599, min: 0 },
		items: 2,
		partialVisibilityGutter: 50
	}
};

const CarouselContainer = ({
	responsive,
	data,
	Title,
	PrimaryComponent,
	SecondaryComponent,
	isCenter,
	isContainer,
	showDots,
	dotListClass,
	arrows,
	partialVisible = false,
	backgroundColor,
	extraMargin = false
}) => {
	const size = useWindowSize();
	const [el, setEl] = React.useState();

	/**
	 * change the slider to the first element when there is a screen size change
	 */
	useEffect(() => {
		if (el && size) el.goToSlide(0);
	}, [el, size]);

	return (
		<div
			className={`${
				isContainer ? `${backgroundColor} w-full max-w-screen-xl mx-auto pb-10` : `${extraMargin? 'mb-10': ''}`
			}`}
		>
			<style>
				{`
					.min-size-arrow {
						min-width: 24px;
						min-height: 24px;
					}

					.react-multiple-carousel__arrow {
						z-index: 10 !important;
					}
				`}
			</style>

			{Title && <Title />}

			<div
				className={`${isContainer ? 'relative px-0 sm:px-10 lg:px-32' : `${extraMargin ? 'mb-10': ''}`}`}
			>
				{data && data.length && (
					<Carousel
						ref={(e) => setEl(e)}
						swipeable
						draggable
						responsive={responsive || responsiveDefault}
						arrows={arrows}
						showDots={showDots}
						dotListClass={dotListClass}
						partialVisible={partialVisible}
						className={`${isCenter ? 'md:flex md:justify-center' : ''}`}
						customRightArrow={
							<KeyboardArrowRightIcon
								className="react-multiple-carousel__arrow min-size-arrow text-pink-500 bg-transparent"
								style={{ right: -7 }}
							/>
						}
						customLeftArrow={
							<KeyboardArrowLeftIcon
								className="react-multiple-carousel__arrow min-size-arrow text-pink-500 bg-transparent"
								style={{ left: -7 }}
							/>
						}
					>
						{data.map((item, i) =>
							PrimaryComponent ? (
								<PrimaryComponent
									key={i}
									item={item}
									index={i}
									lastItem={data.length - 1}
								/>
							) : null
						)}
					</Carousel>
				)}

				{SecondaryComponent && <SecondaryComponent />}
			</div>
		</div>
	);
};

CarouselContainer.defaultProps = {
	data: [],
	PrimaryComponent: null,
	responsive: null,
	Title: null,
	SecondaryComponent: null,
	isCenter: false,
	isContainer: true,
	showDots: false,
	dotListClass: '',
	arrows: true,
	backgroundColor : "bg-white"
};

export default CarouselContainer;
