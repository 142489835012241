import React from 'react';

export const Title = () => (
	<h2 className="text-2xl md:text-4xl leading-snug text-center text-purple-500 font-poppins font-semibold mb-4 md:mb-10">
		Nuestros Socios
	</h2>
);

export const data = [
	{
		icon: '/images/partners/asociacion-fintech.svg',
		text: 'Logo Asociacion Fintech',
		name: 'logo_fintech.svg',
		styles: {
            width: 180,
        },
	},
	{
		icon: '/images/partners/promed.svg',
		text: 'Logo Promed',
		name: 'logo_promed.svg',
		styles: {
            width: 210,
        },
	},
	{
		icon: '/images/partners/amadita.svg',
		text: 'Logo Amadita',
		name: 'logo_amadita.svg',
		styles: {
            width: 210,
        },
	},
	{
		icon: '/images/partners/humano_seguros.svg',
		text: 'Logo Humanos Seguros',
		name: 'logo_humanos_seguros.svg',
		styles: {
            width: 210,
        },
	}
];

export const PrimaryComponent = ({ item }) => (
    <>
        <style>
            {`
                #__next > div > div > div > div:nth-child(11) > div > div > ul {
                    align-items: center !important;
                }

                #__next > div > div > div > div:nth-child(10) > div:nth-child(3) > div {
                    padding-bottom: 5rem;
                }

                @media only screen and (min-width: 960px) and (max-width: 1279px) {
                    #__next > div > div > div > div:nth-child(11) > div > div > ul > li:nth-child(2) > div > div > img {
                        width: 65% !important;
                    }
                }
                @media only screen and (min-width: 1280px) and (max-width: 1520px) {
                    #__next > div > div > div > div:nth-child(11) > div > div > ul > li:nth-child(2) > div > div > img {
                        width: 65% !important;
                    }
                }
            `}
        </style>
        <div className="flex px-5 justify-center items-center flex-col w-full">
            <div className="flex items-center justify-center h-24">
                <img name={item.name} src={item.icon} alt={item.text} className="object-cover" style={item.styles} />
            </div>
        </div>
    </>
);
