import React from 'react';
import TrustPilot from './TrustPilot';

const TrustBox = () => {
  return (
    <div className="bg-gray-100 flex flex-col my-12 pt-8 pb-14 px-5 sm:px-10 lg:px-32">
      <style>
        { `
          .imgTrust {
            margin-top : -14px !important;
          }
        `}

      </style>
      <div className="flex flex-col md:flex-row justify-center items-center text-center mt-10 mb-4">
      <h2 className="text-3xl font-semibold font-poppins">Opiniones sobre
        <span className="font-poppins text-3xl font-bold text-pink-500"> asistensi </span>
        <span className="hidden md:inline-block font-poppins font-semibold"> a través de </span>
      </h2>
      <img src="/images/trustpilot.svg" alt="logo trust pilot" className="h-auto hidden md:flex imgTrust" width="209px" style={{margiTop:'-14px'}}/>
      </div>
        <TrustPilot>
          <div className="trustpilot-widget bg-white py-8 rounded-2xl" data-locale="es-ES" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="62bd917864b947c942a581de" data-style-height="140px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="es" data-font-family="Poppins" data-text-color="#200844"> 
            <a href="https://es.trustpilot.com/review/asistensi.com.do" target="_blank" rel="noopener">Trustpilot</a>
          </div>
        </TrustPilot>
      </div>
  );
};
export default TrustBox;